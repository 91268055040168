import React from 'react';
import Img from 'gatsby-image';

let BannerComponent = ({ width, height, type, value, className }) => {
  // console.log('banner', value.childImageSharp.fluid);

  return (
    <>
      {type == 1 &&
        value &&
        // <img
        //   src={require('../../img/' + value)}
        //   width={width}
        //   height={height}
        //   className='banner-img'
        //   alt=''
        // />

        (value.childImageSharp && value.childImageSharp.fluid ? (
          <Img fluid={value.childImageSharp.fluid} />
        ) : (
          <img
            src={'https://api.skygirlske.com/img/' + value}
            className={className}
            alt=''
          />
        ))}
      {type == 2 && (
        <div className='iframe-parent'>
          <iframe
            title='254'
            width={width}
            height={height}
            src={value}
            frameborder='0'
            className='iframe-preview'
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen></iframe>
        </div>
      )}
    </>
  );
};

export default BannerComponent;
